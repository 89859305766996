<template>
  <div class="pagination">
    <div class="btn-group">
      <template v-if="computedFirstPageOnGroup >= pageCountOnGroup">
        <button class="btn btn-default font-xs pl-2 pr-2" @click="click(0)" :title="`첫 페이지로 이동`">처음</button>
        <button class="btn btn-default" @click="click(computedFirstPageOnGroup - 1)" title="이전 페이지로 이동">
          <i class="fa fa-angle-left"></i>
        </button>
      </template>
      <button class="btn font-xs" :class="[pageable.page === n ? [(className || 'btn-point'), 'active'] : 'btn-default']" v-for="(n, idx) in computedPages" :key="idx" @click="click(n)">{{ n + 1 }}</button>
      <template v-if="computedLastPageOnGroup < lastPageOnTotal">
        <button class="btn btn-default" @click="click(computedLastPageOnGroup + 1)" title="다음 페이지로 이동">
          <i class="fa fa-angle-right"></i>
        </button>
        <button class="btn btn-default font-xs pl-2 pr-2" @click="click(lastPageOnTotal)" :title="`마지막 페이지(${lastPageOnTotal})로 이동`">마지막</button>
      </template>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentPagination";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    pageable: Object,
    total: Number,
    change: Function,
    className: String,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const pageCountOnGroup = 5;
    const lastPageOnTotal = Math.ceil(props.total / props.pageable.size) - 1;

    const computedFirstPageOnGroup = computed(() => {
      return Math.floor(props.pageable.page / pageCountOnGroup) * pageCountOnGroup;
    });

    const computedLastPageOnGroup = computed(() => {
      return computedFirstPageOnGroup.value + pageCountOnGroup - 1;
    });

    const computedPages = computed(() => {
      const pages = [];
      let last = computedLastPageOnGroup.value;
      last = last > lastPageOnTotal ? lastPageOnTotal : last;

      for (let n = computedFirstPageOnGroup.value; n <= last; n += 1) {
        pages.push(n);
      }

      return pages;
    });

    const click = (page) => {
      if (typeof props.change === "function" && props.pageable.page !== page) {
        props.pageable.page = page;
        props.change(page);
      }
    };

    return {component, click, pageCountOnGroup, computedFirstPageOnGroup, computedLastPageOnGroup, lastPageOnTotal, computedPages};
  },
});
</script>

<style lang="scss" scoped>
.pagination {
  display: block;
  text-align: center;

  .btn-group {
    display: inline-block;
  }

  .btn {
    min-width: $px33;
    height: $px33;
    line-height: $px31;
    padding: 0 $px5;

    &:not(.active) {
      background: #fff;

      &:hover {
        background: #eee;
      }
    }
  }
}
</style>